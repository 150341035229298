aside .widget
{
  background-color: #fff;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.4);
  border-radius: 3px;
  margin-bottom:30px;
  clear: both;
  overflow: hidden;
}
aside .widget-button
{
  width: fit-content;
}
aside .widget__header
{
  background-color: #23381d;
  color: #fff;
  min-height: 40px;
  line-height: 20px;
  padding: 10px 20px;
  overflow: hidden;
  border-radius: 3px 3px 0 0;
}
aside .widget__header a
{
  color:#fff;
  text-decoration: none;
}
aside .widget__content
{
  padding: 20px;
}
aside .widget.nopadding .widget__content
{
  padding:0;
}
aside .widget__sidenav ul
{
  display: block;
  margin:0;
  padding:0;
  list-style: none;
}
aside .widget__sidenav ul li
{
  padding: 3px 0;
}
aside .widget__banner .widget__content,
aside .widget__videowidget .widget__content
{
  padding: 0;
}
aside .widget__banner .widget__content a,
aside .widget__videowidget .widget__content a
{
  display: block;
  width: 100%;
  text-align: center;
  line-height: 0;
}
aside .widget__banner .widget__content img,
aside .widget__videowidget .widget__content img
{
  max-width: 100%;
  display: block;
}
aside .widget__content > *:last-child
{
  margin-bottom: 0;
}

aside .widget__courseslist .status-fully-booked,
aside .widget__courseslist .status-cancelled,
aside .widget__courseslist .status-on-hold,
aside .widget__courseslist .status-running
{
  cursor: not-allowed;
  opacity: 0.5;
}
aside .widget__courseslist .status-fully-booked .statuslink:before
{
  content:'Fully booked';
  color:red;
}
aside .widget__courseslist .status-cancelled .statuslink:before
{
  content:'Cancelled';
  color:red;
}
aside .widget__courseslist .status-on-hold .statuslink:before
{
  content:'On hold';
}
aside .widget__courseslist .status-registration .statuslink:before
{
  content:'Register';
}
aside .widget__courseslist .status-running .statuslink:before
{
  content:'Running';
}
aside .widget__courseslist .status-seats-available .statuslink:before
{
  content:'Seats available';
}
aside .widget__courseslist .statuslink:before
{
  content:'Register';
}
aside .widget__courseslist .status-fully-booked h5,
aside .widget__courseslist .status-cancelled h5
{
  opacity: 0.5;
}
aside .widget__courseslist ul,
aside .widget__eventslist ul,
aside .widget__newslist ul
{
  list-style: none;
  margin:0;
  padding:0;
}

aside .widget__courseslist li,
aside .widget__eventslist li,
aside .widget__newslist li
{
  border-bottom: 1px dashed #d5dbe0;
  padding:10px 20px;
  position: relative;
  overflow:hidden;
}
aside .widget__courseslist li:after,
aside .widget__eventslist li:after,
aside .widget__newslist li:after
{
    content: '\f105';
    color: #d9d9d9;
    font-family: 'Font Awesome 5 Pro';
    font-weight: 900;
    position: absolute;
    right: 15px;
    top: 35%;
    font-size: 22px;
}
aside .widget__courseslist li:last-child,
aside .widget__eventslist li:last-child,
aside .widget__newslist li:last-child
{
  border-bottom: none;
}
aside .widget__courseslist li a,
aside .widget__eventslist li a,
aside .widget__newslist li a
{
  text-decoration: none;
}
aside .widget__courseslist h5,
aside .widget__eventslist h5,
aside .widget__newslist h5
{
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
}
aside .widget__courseslist .meta,
aside .widget__eventslist .meta,
aside .widget__newslist .meta
{
  color:#999999;
  font-size: 14px;
}
aside .widget__courseslist .link,
aside .widget__eventslist .link,
aside .widget__newslist .link
{
  color:#f39200;
}
.courseinfourl
{
  display: block;
  text-align: right;
  margin-top: -20px;
}
.morelink
{
  color:#666;
  text-align: right;
  margin-top: -20px;
  float: right;
  margin-bottom: 10px;
}

aside .widget__courseslist .widget__footer
{
  background: #565746;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  padding: 15px 20px;
  text-align: center;
}

aside .widget__courseslist .image,
aside .widget__eventslist .image,
aside .widget__newslist .image
{
  float: left;
  width: 65px;
  height: 65px;
  display: block;
  margin-right: 15px;
  margin-bottom: 5px;
}
aside .widget__newslist .image
{
  display: flex;
}
aside .widget__courseslist .image img,
aside .widget__eventslist .image img,
aside .widget__newslist .image img
{
  width: 100%;
  max-width: 100%;
  height: auto;
}
aside .widget__newslist .image img
{
  align-self: center;
}
.widget-issuuembed
{
   position: relative;
   padding-bottom: 56.25%;
   height: 0;
   overflow: hidden;
   max-width: 100%;
   margin-bottom: 15px;
}
.widget-fbembed
{
  margin-bottom: 15px;
}
.widget-issuuembed iframe
{
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
}
aside .widget-newsletter
{
  background-color:#23381d;
  padding:20px;
}
.widget-newsletter form
{
  margin-bottom:10px;
}
.widget-newsletter a,
.widget-newsletter p
{
  color:#fff;
}
.widget-newsletter .form-control {
  border: none;
}
.widget-newsletter .wh-form.wh-styledinput .wh-form__fieldgroup > .wh-form__fields
{
  max-width: 100%;
}
.anchor
{
  display: block;
  position: relative;
  top: -150px;
  visibility: hidden;
}
.widget-imagegallery img
{
   margin-right: 10px;
   margin-bottom: 10px;
}

.widget-publishergallery
{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
}
.publishergallery__item
{
  padding: 1em;
  border-bottom: 1px solid #ccc;
}
.publishergallery__item h2
{
  display: block;
  min-height: 60px;
}
.publishergallery__item img
{
  max-width: 100%;
  width: 100%;
}
.publishergallery__item button
{
  margin-top: 10px;
  cursor: pointer;
}
.publishergallery__item:hover .publisher__gallery
{
  text-decoration: none;
}
@media (max-width: 565px)
{
  .widget-publishergallery
  {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 10px;
  }
  .publishergallery__item button
  {
    width: 100%;
  }
}
