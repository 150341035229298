html.page--photoalbum article {
  max-width: 100%;
  flex: 1 1 100%;
}
html.page--photoalbum article .medialist__items {
  float: left;
  padding: 0 10px 0 0;
  margin: 0;
}
html.page--photoalbum article .medialist__item {
  list-style: none;
  margin: 0;
  padding: 0;
}
html.page--photoalbum article .medialist__image img {
  max-width: 250px;
}