/* Colors */
/* button  colors */
/* width and heights */
/* left/right padding contentarea */
.courselist__items {
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
  padding: 10px;
  align-items: center;
  min-height: 65px;
  border-top: 1px solid #dee2e6;
}
.courselist__items.Rtable-cell--head {
  min-height: 30px;
}
.courselist__items:not(.Rtable-cell--head):nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}
.courselist__items div a:hover {
  text-decoration: none;
}
.courselist__title {
  font-size: 16px;
  color: #4782b2;
  display: block;
}
.courselist__title .Rtable-cell {
  padding-left: 10px;
}
.courselist__title .Rtable-cell .courselist_date,
.courselist__title .Rtable-cell .courselist_location {
  color: #87888a;
  font-size: 14px;
  display: block;
}
.courselist__description {
  display: none;
}
.courselist__date {
  background-color: #fff;
  height: 55px;
  width: 100%;
}
.courselist__startday {
  font-size: 16px;
  font-weight: 700;
  width: 65px;
  line-height: 31px;
  width: 100%;
  text-align: center;
  display: block;
}
.courselist__startmonth {
  display: block;
  width: 100%;
  height: 22px;
  line-height: 22px;
  font-weight: 600;
  text-align: center;
  background-color: #23381d;
  color: #fff;
}
.courselist .btn {
  float: right;
}

.Rtable-cell {
  box-sizing: border-box;
  -webkit-box-flex: 1;
  flex-grow: 1;
  width: 100%;
  overflow: hidden;
  list-style: none;
}

.Rtable-col1 {
  width: 12%;
}

.Rtable-col2 {
  width: 63%;
}

.Rtable-col3 {
  width: 25%;
}

@media all and (max-width: 500px) {
  .Rtable--collapse {
    display: block;
  }
  .Rtable--collapse > .Rtable-col1 {
    width: 25% !important;
    float: left;
  }
  .Rtable--collapse > .Rtable-col2 {
    width: 75% !important;
  }
  .Rtable--collapse > .Rtable-col3 {
    width: 100% !important;
  }
}
.courselist__items.status-fully-booked,
.courselist__items.status-cancelled,
.courselist__items.status-on-hold,
.courselist__items.status-running {
  cursor: not-allowed;
  opacity: 0.5;
}

.courselist__items.status-fully-booked .btn-orange:before {
  content: "Fully booked";
}

.courselist__items.status-cancelled .btn-orange:before {
  content: "Cancelled";
}

.courselist__items.status-on-hold .btn-orange:before {
  content: "On hold";
}

.courselist__items.status-registration .btn-orange:before {
  content: "Register";
}

.courselist__items.status-running .btn-orange:before {
  content: "Running";
}

.courselist__items.status-seats-available .btn-orange:before {
  content: "Seats available";
}

.courselist__items .btn-orange:before {
  content: "Register";
}

.courselist__items.status-fully-booked .courselist__title,
.courselist__items.status-cancelled .courselist__title {
  cursor: not-allowed;
}

.coursestatus--fully-booked .article__body form {
  display: none;
}
.coursestatus--fully-booked .article__body:after {
  content: " This course is fully booked";
  color: red;
}

.coursestatus--cancelled .article__body form {
  display: none;
}
.coursestatus--cancelled .article__body:after {
  content: " This course has been cancelled";
  color: red;
}

.coursestatus--on-hold .article__body form {
  display: none;
}
.coursestatus--on-hold .article__body:after {
  content: " This course has been put on hold";
  color: red;
}