/* Colors */
/* button  colors */
/* width and heights */
/* left/right padding contentarea */
.page--events article {
  box-shadow: none;
  background-color: transparent;
}
.page--events article .article__header {
  position: relative;
}
.page--events article .article__header .article__meta {
  padding: 10px 20px 0 20px;
}
.page--events article .article__header .article__meta .article__date {
  margin-right: 10px;
}
.page--events article .article__body {
  padding: 0;
  box-shadow: none;
  overflow: inherit;
}
.page--events .eventlist .pagedescription {
  font-size: 17px;
  font-weight: 400;
  padding-bottom: 30px;
}
.page--events .eventlist ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-flow: row;
  flex-wrap: wrap;
}
.page--events .eventlist ul li {
  background-color: #fff;
  max-width: 730px;
  flex: 1 1 66.6666%;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.4);
  border-radius: 3px;
  margin-bottom: 20px;
}
.page--events .eventlist ul li .image > img {
  vertical-align: bottom;
  max-width: 100%;
  height: auto;
  border-radius: 3px 3px 0 0;
}
.page--events .eventlist ul li .eventlist__top {
  display: block;
  padding: 15px 15px 0 15px;
}
.page--events .eventlist ul li .eventlist__country {
  margin-right: 15px;
}
.page--events .eventlist ul li .title {
  padding: 15px 15px 0 15px;
  font-size: 20px;
  margin-bottom: 0;
  font-weight: normal;
  line-height: 120%;
}
.page--events .eventlist ul li .description {
  padding: 15px 15px 0 15px;
}
.page--events .eventlist ul li .btn {
  display: inline-block;
  margin-left: 15px;
  margin-bottom: 15px;
}
.page--events .eventlist ul a {
  display: block;
  text-decoration: none;
  color: inherit;
}
.page--events .eventlist ul a .more {
  color: #f47421;
  text-decoration: underline;
  float: right;
  padding: 0 15px 15px 0;
}

.page--eventitem article {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.4);
  background-color: #fff;
}
.page--eventitem article .article__body {
  padding: 20px;
}