/* Colors */
/* button  colors */
/* width and heights */
/* left/right padding contentarea */
html {
  background-color: #f2f2f2;
  min-height: 100%;
  display: flex;
  flex-direction: row; /* IE10/11 Workaround for child elements with flex-direction: column to outfill content area (main) */
}

body {
  -webkit-font-smoothing: antialiased;
  overflow: hidden;
  overflow-y: auto;
  display: flex;
  width: 100vw;
  flex-direction: column;
  min-height: 100vh;
  padding-top: 140px;
}
body.scroll {
  padding-top: 110px;
}

main {
  position: relative;
  flex: 1 0 auto; /* vertical (column) outfill */
  max-width: 100%;
  display: flex; /* article and aside */
  flex-flow: row;
  flex-wrap: nowrap;
  padding-bottom: 40px;
  border-radius: 3px;
}
main.sidebarboth aside {
  max-width: 255px;
}

aside {
  flex: 1 1 33.3333%;
  max-width: 350px;
  width: 100%;
}
aside.sidebar__right {
  margin-left: 30px;
}
aside.sidebar__left {
  margin-right: 30px;
}
aside a {
  color: #f39200;
}

h1, h2, h3, h4, h5, h6,
h1 a, h2 a, h3 a, h4 a, h5 a, h6 a,
a h1, a h2, a h3, a h4, a h5, a h6 {
  color: #23381d;
}

p {
  text-align: justify;
}

.d-none {
  display: none;
}

.float-right {
  float: right;
}

@media (max-width: 800px) {
  main {
    display: block;
  }
  main.sidebarboth {
    display: flex;
    flex-wrap: wrap;
  }
  main.sidebarboth article {
    order: 1;
  }
  main.sidebarboth aside {
    max-width: 100%;
    order: 2;
    flex: none;
    display: block;
  }
  main aside {
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
  }
}
.centercontent {
  width: 100%;
  max-width: 1150px;
  padding-left: 20px;
  padding-right: 20px;
  margin-left: auto;
  margin-right: auto;
}

.pagenav {
  min-height: 40px;
  line-height: 20px;
}
.pagenav ol {
  list-style: none;
  padding: 0;
  display: block;
  margin: 6px 0;
  overflow: hidden;
}
.pagenav ol li {
  float: left;
}
.pagenav ol li a {
  color: #23381d;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 500;
  text-decoration: none;
}
.pagenav ol li:last-child a {
  color: #999;
}
.pagenav ol li:not(:first-child):before {
  content: "/";
  padding: 0 10px;
  color: #ccc;
}
@media (max-width: 600px) {
  .pagenav {
    min-height: 20px;
  }
}

article {
  background-color: #fff;
  max-width: 730px;
  flex: 1 1 66.6666%;
  border-radius: 3px;
  height: max-content;
}
article .article__header {
  border-radius: 3px 3px 0 0;
}
article .article__header img {
  max-width: 100%;
  height: auto;
}
article .article__body {
  padding: 20px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.4);
  overflow: hidden;
}
article .content {
  padding: 20px 20px 0 20px;
}
article .content h1 {
  margin-bottom: 0;
}
article.fullwidth {
  max-width: 100%;
}

header, aside, footer {
  flex: none;
}

.fa, .fas {
  font-weight: normal;
}

.btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  text-decoration: none;
}
.btn:hover {
  -webkit-box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}
.btn.btn-orange {
  background-color: #f39200;
  border-color: #f39200;
  color: #fff;
}
.btn.btn-orange:hover, .btn.btn-orange:focus {
  background-color: #ffa218;
  border-color: #ffa218;
  text-decoration: none;
  color: #fff;
}
.btn.btn-green {
  background-color: #35693e;
  border-color: #35693e;
  color: #fff;
}
.btn.btn-green:hover, .btn.btn-green:focus {
  background-color: #41824c;
  border-color: #41824c;
  text-decoration: none;
  color: #fff;
}
.btn.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}
.btn.btn-lg {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}
.btn-link {
  box-shadow: none;
}

.z-depth-1 {
  -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

.overview__image img {
  max-width: 100%;
  height: auto;
}

.website--showcookiebar:after {
  content: "";
  background-color: rgba(0, 0, 0, 0.65);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 1020;
  pointer-events: none;
}

.cookiebar__notice {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 500px;
  min-width: 350px;
  z-index: 1030;
  background-color: #fff;
  padding: 20px;
  box-shadow: 0px 0px 60px rgba(0, 0, 0, 0.5);
}
.cookiebar__notice h2 {
  margin-bottom: 10px;
}
.cookiebar__notice p a {
  color: #f39200;
}
.cookiebar__notice.bottom {
  max-width: 100%;
  min-width: 100%;
  transform: none;
  left: 0;
  top: inherit;
  bottom: 0;
}
.cookiebar__notice.bottom > div {
  max-width: 1150px;
  margin: 0 auto;
}
.cookiebar__notice.bottom > div .accept-cookie {
  max-width: 200px;
  display: inline-block;
}
.cookiebar__notice .btn-link {
  vertical-align: baseline;
  border: 0;
  font-size: inherit;
  box-shadow: none;
  font-weight: normal;
  background: none;
  padding: 0;
  margin: 0 0 0 15px;
  cursor: pointer;
}
.cookiebar__notice .btn-link:hover, .cookiebar__notice .btn-link:focus {
  box-shadow: none;
}
.cookiebar__notice .btn-link:before {
  display: none;
}
.cookiebar__notice .accept-cookie {
  min-width: 200px;
  width: 100%;
  text-align: center;
  cursor: pointer;
}
@media (max-width: 575px) {
  .cookiebar__notice .accept-cookie {
    width: 100%;
  }
}
.cookiebar__notice a {
  text-decoration: underline;
}