/* Colors */
/* button  colors */
/* width and heights */
/* left/right padding contentarea */
.topsearch > form > *,
.sidesearch > form > * {
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  height: 44px;
  border: 0 none;
  font-size: 16px;
}
.topsearch > form input,
.sidesearch > form input {
  height: calc(1.8125rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem 0 0 0.2rem;
  border: 1px solid #a1a2a9;
  border-right: 0;
  width: 240px;
}
.topsearch > form button,
.sidesearch > form button {
  height: calc(1.8125rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0 0.2rem 0.2rem 0;
  background-color: #35693e;
  color: #fff;
  cursor: pointer;
}
.topsearch > form button:hover,
.topsearch > form button:focus,
.sidesearch > form button:hover,
.sidesearch > form button:focus {
  background-color: #btn_green_hover;
}

.searchpage article {
  background-color: transparent;
}
.searchpage article .article__body {
  padding: 0;
  box-shadow: none;
  overflow: inherit;
}
.searchpage article .article__body ul.searchresults {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.searchpage article .article__body ul.searchresults li {
  margin-bottom: 20px;
  background-color: white;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 0 2px 3px rgba(0, 0, 0, 0.4);
  -webkit-box-shadow: 0 2px 3px rgba(0, 0, 0, 0.4);
  border-radius: 3px;
  padding-bottom: 20px;
}
.searchpage article .article__body ul.searchresults li a {
  color: inherit;
  text-decoration: none;
  line-height: 170%;
  display: block;
  padding: 20px;
}
.searchpage article .article__body ul.searchresults li a .title {
  padding-bottom: 5px;
  color: #23381d;
  font-weight: 500;
  font-size: 1.25rem;
}
.searchpage article .article__body ul.searchresults li a .link {
  padding-top: 5px;
  color: #23381d;
}
.searchpage article .article__body ul.searchresults li a .viewmore {
  float: right;
  color: #f39200;
}
.searchpage article .article__body ul.searchresults li a:hover .link {
  text-decoration: underline;
}
.searchpage article .article__body ul.searchresults li a > * {
  display: block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}