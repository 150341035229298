/* mobile left sidemenu: */
#mainSideNav {
  visibility: hidden;
  border-top: 0;
}
#mainSideNav .close-btn {
  width: 42px;
  height: 42px;
  display: block;
  position: absolute;
  display: block;
  padding: 5px 5px;
  right: 0;
  top: 0;
}
#mainSideNav .close-btn:before {
  content: "";
  width: 15px;
  margin: 50% auto 0 auto;
  height: 2px;
  background: #fff;
  display: block;
  -webkit-transition: all 300ms;
  transition: all 300ms;
  transform: translateY(-1px) rotate(45deg);
}
#mainSideNav .close-btn:after {
  content: "";
  width: 15px;
  margin: 0 auto;
  height: 2px;
  margin-top: -2px;
  background: #fff;
  display: block;
  -webkit-transform: translateY(-1px) rotate(-90deg);
  transform: translateY(-1px) rotate(-45deg);
  -webkit-transition: all 300ms;
  transition: all 300ms;
}
#mainSideNav .webshop-search input,
#mainSideNav .input-group > .input-group-append > .btn {
  border: none;
  border-radius: 0;
}
#mainSideNav ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
#mainSideNav ul li:first-child {
  overflow: hidden;
}
#mainSideNav ul li:first-child img {
  float: left;
  margin-top: 5px;
  font-size: 12px;
}
#mainSideNav ul li:first-child span {
  float: left;
  color: #fff;
}
#mainSideNav [sidebarjs-container],
#mainSideNav [sidebarjs-backdrop] {
  transition: all 0.3s ease;
}
#mainSideNav [sidebarjs-container] {
  background: #2b4524;
  overflow-y: auto;
}
#mainSideNav [sidebarjs-container] .nav-link {
  display: block;
  font-size: 14px;
  padding: 9px 1rem;
}
#mainSideNav [sidebarjs-container] a,
#mainSideNav [sidebarjs-container] h3 {
  color: #fff;
}
#mainSideNav.sidebarjs--is-visible {
  visibility: visible;
}
#mainSideNav .submenu:not(.d-none) {
  display: none;
}
#mainSideNav .submenu.d-block {
  display: block;
}
#mainSideNav .has-submenu {
  position: relative;
}
#mainSideNav .has-submenu .nav-link {
  display: block;
  margin-right: 42px;
  font-size: 14px;
}
#mainSideNav .has-submenu > .submenu-toggle {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
  display: block;
  float: right;
  width: 40px;
  height: 40px;
  border-left: 1px solid rgba(0, 0, 0, 0.3);
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  -webkit-transition: all 200ms ease-out;
  transition: all 200ms ease-out;
  box-sizing: border-box;
}
#mainSideNav .has-submenu > .submenu-toggle:last-child {
  border-top: none;
}
#mainSideNav .has-submenu > .submenu-toggle:before {
  content: "";
  width: 15px;
  margin: 50% auto 0 auto;
  height: 2px;
  background: #fff;
  display: block;
  -webkit-transition: all 300ms;
  transition: all 300ms;
}
#mainSideNav .has-submenu > .submenu-toggle:after {
  content: "";
  width: 15px;
  margin: 0 auto;
  height: 2px;
  background: #fff;
  display: block;
  -webkit-transform: translateY(-1px) rotate(-90deg);
  transform: translateY(-1px) rotate(-90deg);
  -webkit-transition: all 300ms;
  transition: all 300ms;
}
#mainSideNav .has-submenu > .submenu-toggle:hover {
  cursor: pointer;
}
#mainSideNav .has-submenu > .submenu-toggle.collapse--open:before {
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
}
#mainSideNav .has-submenu > .submenu-toggle.collapse--open:after {
  -webkit-transform: translateY(-1px) rotate(-360deg);
  transform: translateY(-1px) rotate(-360deg);
  opacity: 0;
}
#mainSideNav ul li {
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  margin-top: -1px;
}
#mainSideNav ul li ul li {
  border: none;
}
#mainSideNav ul.active li {
  border: none;
}
#mainSideNav ul li.active {
  border-top: 0;
}
#mainSideNav ul.submenu {
  margin-left: 0.5rem;
}

@media (max-width: 767px) {
  .sidebartoggle {
    display: block;
    width: 100%;
    height: 20px;
    position: relative;
    top: 10px;
    color: #fff;
  }
  .sidebartoggle img {
    float: right;
    margin-top: 3px;
  }
}
@media (min-width: 768px) {
  #mainSideNav {
    display: none;
  }
}