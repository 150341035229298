/* Colors */
/* button  colors */
/* width and heights */
/* left/right padding contentarea */
.wh-pagination {
  list-style: none;
  margin: 0;
  padding: 0;
  border: 0 none;
}
.wh-pagination li {
  display: inline-block;
  background-color: #fff;
  text-align: center;
}
.wh-pagination li + li {
  margin-left: 2px;
}
.wh-pagination span, .wh-pagination a {
  display: block;
  padding: 5px 12px;
  border-radius: 3px;
}
.wh-pagination a {
  color: #23381d;
  text-decoration: none;
}
.wh-pagination a:hover {
  background-color: #ddd;
}
.wh-pagination li.active a {
  background-color: #23381d;
  color: #fff;
}
.wh-pagination span::before {
  content: "...";
  display: inline-block;
}