/* Colors */
/* button  colors */
/* width and heights */
/* left/right padding contentarea */
footer {
  margin-bottom: 0px;
  color: #e0e3e0;
  clear: both;
  overflow: hidden;
}
footer a {
  color: #fff;
}
footer .site-footer {
  background: url(../web/img/footer-bg.png) no-repeat bottom left #23381d;
  padding: 30px 0;
}
footer .site-footer .centercontent {
  display: flex;
}
@media (max-width: 767px) {
  footer .site-footer .centercontent {
    display: block;
  }
}
footer .site-footer h4 {
  color: #fff;
  display: block;
  margin: 0 0 10px;
  padding: 0 0 10px;
  font-size: 21px;
}
footer .site-footer a, footer .site-footer h4 {
  color: #fff;
}
footer .site-footer a:hover {
  color: #fff;
}
footer .site-footer #social-media {
  margin: 20px 0 0;
}
footer .site-footer #social-media svg {
  width: 1.3rem;
  height: 1.3rem;
  margin-right: 0.5rem;
}
footer .site-footer .footer__widget {
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}
footer .site-footer .footer__widget img {
  max-width: 100%;
  height: auto;
}
@media (max-width: 767px) {
  footer .site-footer .footer__widget {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
footer .site-footer .footer__widget.messenger img {
  margin: 0 auto;
  display: block;
}
footer .site-footer .footer__widget .messenger-wrapper {
  background-color: #2b4524;
  padding: 20px;
  margin-top: -10px;
  position: relative;
  z-index: 1;
}
footer .site-footer .footer__widget .messenger-wrapper .wh-form.wh-styledinput .wh-form__fieldgroup > .wh-form__fields {
  max-width: 100%;
}
footer .site-footer .footer__widget .messenger-wrapper .btn {
  margin-bottom: 10px;
}
footer .site-footer .footer__widget ul {
  display: table;
  list-style: none;
  padding: 0;
  margin: 0 -7.5px 20px -7.5px;
}
footer .site-footer .footer__widget ul li {
  display: table-row;
}
footer .site-footer .footer__widget ul li:before {
  content: "\f105";
  width: 1.5em;
  font-size: 12px;
  line-height: 28px;
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  display: table-cell;
  padding-left: 7px;
  color: #fff;
}
footer .site-footer .footer__widget ul li a {
  font-size: 12px;
  font-weight: normal;
  text-decoration: underline;
}
footer .site-footer .footer__widget ul li:first-child:before {
  content: "";
  width: 0;
}
footer .site-footer .footer__widget ul li:first-child a {
  font-size: 13px;
  font-weight: bold;
  text-decoration: none;
}
footer .bottom-footer {
  background: #1c2b17;
  color: #fff;
  margin-top: 0px;
  border-top: 0;
  padding: 20px 0;
  margin-bottom: 0;
  font-size: 12px;
}