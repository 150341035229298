/* Colors */
/* button  colors */
/* width and heights */
/* left/right padding contentarea */
@import "/.publisher/sd/ccoe/cimic-coe/fonts/fontawesome/css/all.min.css";
.homepage {
  background-color: #fff;
}
.homepage .homeheader {
  min-height: 547px;
  display: flex;
  margin-bottom: 40px;
}
.homepage .homeheader .centercontent {
  display: flex;
}
.homepage .homeheader__content {
  align-self: center;
}
.homepage .homeheader__content h1 {
  font-size: 52px;
  color: #fff;
  font-weight: 600;
  text-shadow: 0 2px 2px rgba(0, 0, 0, 0.6);
}
.homepage .homeheader__content p,
.homepage .homeheader__content li {
  font-size: 24px;
  color: #fff;
  font-weight: 300;
  line-height: 32px;
  text-shadow: 0 2px 2px rgba(0, 0, 0, 0.6);
}
.homepage .homeheader__content ul {
  margin: 0 0 10px 0;
  padding: 0;
}
.homepage .homeheader__content ul li {
  position: relative;
  list-style-type: none;
  padding: 3px 0px 3px 35px;
}
.homepage .homeheader__content ul li:before {
  content: "\f00c";
  position: absolute;
  left: 10px;
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  font-size: 21px;
  top: 2px;
}
@media (max-width: 767px) {
  .homepage .homeheader__content h1 {
    font-size: 38px;
  }
  .homepage .homeheader__content p,
  .homepage .homeheader__content li {
    font-size: 21px;
    line-height: 28px;
  }
}
.homepage article {
  max-width: 625px;
  flex: 1 1 100%;
  box-shadow: none;
}
.homepage .mediagallery {
  margin-bottom: 50px;
}
.homepage .mediagallery__content {
  text-align: center;
  background: #23381d;
  position: relative;
  padding: 20px;
  margin-bottom: 10px;
}
.homepage .mediagallery__content h3 {
  color: #fff;
}
.homepage .mediagallery__content .glide__arrow {
  border: none;
  font-size: 24px;
  top: 40%;
}
.homepage .mediagallery__content .glide__arrow--right {
  right: -20px;
}
.homepage .mediagallery__content .glide__arrow--left {
  left: -20px;
}
.homepage .mediagallery__gradient__left {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 15%;
  height: 100%;
  background: linear-gradient(to right, #23381d, transparent 100%);
}
.homepage .mediagallery__gradient__right {
  z-index: 1;
  position: absolute;
  top: 0;
  right: 0;
  width: 15%;
  height: 100%;
  background: linear-gradient(to left, #23381d, transparent 100%);
}
.homepage .mediagallery__item a {
  color: #fff;
  text-decoration: none;
}
.homepage .mediagallery__item img {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
  margin-bottom: 0.25rem !important;
}
.homepage aside.sidebar__right {
  flex: 1 1 33.3333%;
  max-width: 455px;
  width: 100%;
  margin-top: -80px;
  margin-left: 30px;
}
@media (max-width: 767px) {
  .homepage aside.sidebar__right {
    margin-top: 0;
    margin-left: 0;
  }
}

.newsgallery {
  margin-bottom: 50px;
}
.newsgallery__content {
  text-align: center;
  position: relative;
  margin-bottom: 10px;
}
.newsgallery__content h3 {
  text-align: left;
  margin-bottom: 0;
}
.newsgallery__content .glide__arrow {
  font-size: 24px;
  top: 45%;
}
.newsgallery__content a:hover {
  text-decoration: none !important;
}
.newsgallery__item img {
  max-width: 100%;
  height: auto;
  margin-bottom: 0.25rem !important;
}
.newsgallery__item .caption h5 {
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
}
.newsgallery__item .caption .date {
  color: #999999;
}