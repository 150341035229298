/* Colors */
/* button  colors */
/* width and heights */
/* left/right padding contentarea */
header {
  background-color: #fff;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.4);
  z-index: 100; /* keep on top */
}
header::after {
  clear: both;
  content: "";
  display: block;
}
header a {
  color: inherit;
  text-decoration: none;
}
header .logo {
  text-decoration: none;
  color: inherit;
  display: block;
  height: 100%;
  font-size: 13px;
  line-height: 150%;
  color: #666;
  padding: 8px 0;
  float: left;
}
header .logo img {
  max-height: 80px;
  height: 80px;
  transition: all 0.2s ease;
  margin-right: 10px 0;
}
header .logo .sitetitle {
  font-size: 18px;
  line-height: normal;
  color: #666666;
  display: block;
  float: right;
  margin-top: 20px;
  margin-left: 10px;
}
header .topnav {
  float: right;
  font-size: 12px;
  color: #42463a;
  white-space: nowrap;
  line-height: 21px;
}
header .topnav a:hover {
  text-decoration: underline;
}
header .topnav ul {
  list-style-type: none;
  margin: 4px 0;
  padding: 0;
  text-align: right;
}
header .topnav ul > li {
  display: inline-block;
}
header .topnav ul > li + li::before {
  content: "|";
  display: inline-block;
  padding: 0 5px;
}
header .menubar {
  position: absolute;
  bottom: -40px;
  left: 0;
  right: 0;
  background-color: #23381d;
  height: 40px;
}
header .menubar > .centercontent {
  display: flex;
  flex-flow: row;
  flex-wrap: nowrap;
  justify-content: space-between;
}
header .menubar .mainnav ul {
  margin: 0;
  padding: 0;
}
header .menubar .mainnav ul li {
  display: block;
  position: relative;
  float: left;
  border-right: 1px solid #36522a;
}
header .menubar .mainnav ul li:hover {
  background-color: #36522a;
}
header .menubar .mainnav ul li:hover a:hover {
  background-color: #36522a;
}
header .menubar .mainnav ul li ul {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: visibility 0s, opacity 0.3s linear;
}
header .menubar .mainnav ul li ul li {
  border-bottom: 1px solid #36522a;
}
header .menubar .mainnav ul li a {
  display: block;
  text-decoration: none;
  white-space: nowrap;
  color: #fff;
  font-size: 16px;
  line-height: 20px;
  padding: 10px 20px;
  background-color: #23381d;
  transition: background-color 0.2s;
}
header .menubar .mainnav ul li a.active {
  background-color: #36522a;
}
header .menubar .mainnav ul li:hover > ul {
  visibility: visible;
  position: absolute;
  opacity: 1;
  margin-left: 0;
  padding-left: 0;
}
header .menubar .mainnav ul li:hover li {
  float: none;
}
header .menubar .mainnav ul li:hover a {
  background: #23381d;
}
header .menubar .mainnav ul li:hover li a:hover {
  background: #36522a;
}
header .menubar .mainnav ul ul ul,
header .menubar .mainnav ul ul ul ul {
  left: 100%;
  top: 0;
}
header .menubar .mainnav ul ul:before,
header .menubar .mainnav ul ul:after {
  content: " ";
  display: table;
}
header .menubar .mainnav ul ul:after {
  clear: both;
}
header .socialnav {
  font-size: 21px;
  line-height: 40px;
  list-style-type: none;
  margin: 0;
  padding: 0;
  white-space: nowrap;
}
header .socialnav a {
  opacity: 1;
  transition: opacity 0.2s;
  display: block;
  color: #fff;
}
header .socialnav a:hover {
  opacity: 0.8;
}
header .socialnav li {
  padding-right: 20px;
  display: inline-block;
}
@media (min-width: 768px) {
  header .sidebartoggle {
    display: none;
  }
}
@media (max-width: 767px) {
  header .topsearch,
  header .mainnav,
  header .socialnav,
  header .topnav {
    display: none;
  }
}
header.smallheader .logo {
  margin: 0;
  padding: 0;
}
header.smallheader .logo img {
  max-height: 50px;
  margin: 7px 0 2px 0;
}
header.smallheader .logo span {
  font-size: 13px;
  margin-top: 17px;
}