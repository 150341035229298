@import url('//fonts.googleapis.com/css?family=Roboto:300,300i,400,400i,500,700,700i');
@import "/.publisher/sd/ccoe/cimic-coe/fonts/fontawesome/css/all.min.css";

html
{
  font: 15px/140% 'Roboto', sans-serif, Arial;
  color: #87888a;
}

h1, h2, h3
{
  color: #23381d; /* green_dark */
  line-height: 120%;
  margin-bottom: 20px
}

article a[href]
{
  color: #f39200;
  text-decoration: none;
}
article a[href]:hover
{
  text-decoration: underline;
}

.embeddedwidget
{
  margin-bottom: 20px;
}
.wh-rtd-editor .wh-rtd-embeddedobject
{
  margin-bottom: 20px;
}
.wh-rtd-editor .embeddedwidget
{
  margin-bottom: 0;
}

h1
{
  font-size: 28px;
}

h2, h3
{
  font-size: 24px;
}

p
{
  margin-bottom: 20px
}
hr
{
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid rgba(0,0,0,.1);
}
sup
{
  vertical-align: top;
  line-height: 100%;
}

sub
{
  vertical-align: bottom;
  line-height: 100%;
}

/* buttons */
.btn
{
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: .25rem;
  transition: color .15s ease-in-out,
  background-color .15s ease-in-out,
  border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  text-decoration: none;
}

.btn-orange
{
  background-color: #f39200;
  border-color: #f39200;
  color:#fff;
}
.btn-green
{
  background-color: #35693e;
  border-color: #35693e;
  color:#fff;
}
/* inline images */
.wh-rtd__img
{
  max-width: 100%;
  height: auto;
}
.wh-rtd__img--floatleft,
{
  margin: 0 15px 15px 0;
}
.wh-rtd__img--floatright
{
  margin: 0 0 15px 15px;
}

.wh-rtd__img--floatleft + .img__caption
{
  float:left;
  margin-top: 5px;
  clear: both;
  max-width: 100%;
}
.wh-rtd__img--floatright + .img__caption
{
  float:right;
  margin-top: -10px;
  clear: both;
  max-width: 100%;
  text-align: right;
  height: auto;
}

/* lists */
  ul.unordered
, ol.ordered
{
  margin-top: 0;
  padding-bottom: 0;
  padding-top: 0;
  list-style-type: none;
  margin-left: 0;
  padding-left: 0;
}
  article > ul.unordered
, article > ol.ordered
{
  margin-bottom: 20px;
}
  p + ul.unordered
, p + ol.ordered
{
  margin-top: -20px;/* compensate p margin */
  margin-bottom: 20px;
}
  ul.unordered > li
, ol.ordered > li
{
  padding-left: 20px;
  position: relative;
  margin: 0;
  line-height: 150%;
}
ul.unordered > li::before
{
  content: '•';
  display: inline-block;
  position: absolute;
  color: inherit;
  top: 0;
  left: 5px;
}
ol.ordered
{
  counter-reset: li;
}
ol.ordered > li::before
{
  content: counter(li) ".";
  counter-increment: li;
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  font-size: 15px;
}
ul.checkmarks
{
    margin:0 0 10px 0;
    padding:0
}
ul.checkmarks li
{
    position: relative;
    list-style-type: none;
    padding: 3px 0px 3px 35px;
}
ul.checkmarks li:before
{
    content: '\f00c';
    position: absolute;
    left: 10px;
    font-family: 'Font Awesome 5 Pro';
    font-weight: 900;
    font-size: 21px;
    top: 2px;
    color: #75b325;
}
.heading2_centered,
.heading3_centered,
.centered
{
  text-align: center;
}
/* tables */
.wh-rtd__tablewrap
{
  overflow-x: auto;
  margin-bottom: 20px;
  max-width: 100%;
}
.wh-rtd__table
{
  width: 100%;
}
.wh-rtd__table td, .wh-rtd__table th
{
  vertical-align: top;
  text-align: left;
}
  .wh-rtd__table td > *:last-child
, .wh-rtd__table th > *:last-child
{
  margin-bottom: 0;
}
  .wh-rtd--hascolheader > *
, .wh-rtd--hasrowheader th
{
  font-weight: bold;
  color: #fff;
  padding-left: 15px;
  background-color: #5d8056; /* $green_light */
}
.wh-rtd--hascolheader ~ tr > td:first-child
{
  padding-left: 15px;
}
.wh-rtd__table * + *
{
  padding-left: 15px;
}
  .wh-rtd__table th
, .wh-rtd__table td
{
  padding-top: 5px;
  padding-bottom: 5px;
}
  .wh-rtd__table th > p:last-child
, .wh-rtd__table td > p:last-child
{
  padding-bottom: 0;
}
.wh-rtd__table tr > *
{
  border-bottom: 0px solid #5d8056; /* $green_light */
}
.wh-rtd__table .wh-rtd--hascolheader + tr > td
{
  border-top: 0 none;
}


/* widgets */

/* two colums */
.widget-twocolumns
{
  display: flex;
  align-items:center;
}
.widget-twocolumns > .col
{
  flex: 0 1 50%;
  max-width: 50%;
  padding-right: 15px;
}
.widget-twocolumns > .col + .col
{
  padding-left: 15px;
  padding-right: 0;
}
@media(max-width: 600px)
{
  .widget-twocolumns
  {
    display: block;
  }
  .widget-twocolumns > .col
  {
    flex: 0 1 100%;
    max-width: 100%;
    padding-right: 0;
  }
  .widget-twocolumns > .col + .col
  {
    padding-left: 0;
  }
}
